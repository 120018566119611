import type { Resolvers } from '../../__codegen__/__graphql__/resolvers'

export const UserKeyword: Resolvers['UserKeyword'] = {
  id(parent) {
    return `UserKeyword#${parent.keyword}`
  },
  isBannedKeyword(parent) {
    return parent.is_banned_keyword
  },
  canRegisterKeyword(parent) {
    return parent.is_recommend_notification_keyword
  },
}
