import type { SearchPlaceNodeResolvers } from '../../__codegen__/__graphql__/resolvers'
import { V1PlaceComponentType } from '../../__codegen__/__openapi__/search-server/place/v4/place'
import { PLACE_COMPONENT_TYPE } from '../constants'

export const SearchPlaceNode: SearchPlaceNodeResolvers = {
  componentType(parent) {
    switch (parent.componentType) {
      case V1PlaceComponentType.PLACE: {
        return PLACE_COMPONENT_TYPE.PLACE
      }
      case V1PlaceComponentType.BANNER: {
        return PLACE_COMPONENT_TYPE.PLACE_CREATE_BANNER
      }
      case V1PlaceComponentType.UNSPECIFIED:
      default: {
        return PLACE_COMPONENT_TYPE.UNKNOWN
      }
    }
  },
}
