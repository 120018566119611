import type { SearchCommunityNodeResolvers } from '../../__codegen__/__graphql__/resolvers'
import { COMMUNITY_COMPONENT_TYPE } from '../constants'

export const SearchCommunityNode: SearchCommunityNodeResolvers = {
  componentType(parent) {
    switch (parent.componentType) {
      case 'COMMUNITY_COMPONENT_TYPE_COMMUNITY': {
        return COMMUNITY_COMPONENT_TYPE.COMMUNITY
      }
      case 'COMMUNITY_COMPONENT_TYPE_UNSPECIFIED':
      default: {
        return COMMUNITY_COMPONENT_TYPE.UNKNOWN
      }
    }
  },
  meta(parent) {
    return {
      index: parent.meta?.index ?? 0,
      cursor: parent.meta?.cursor ?? '',
      id: parent.meta?.id ?? '',
    }
  },
}
