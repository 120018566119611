import { convertStringToDate } from '../../_app/utils/convertStringToDate'
import { nonNullableTypeSafe } from '../../_app/utils/helpers'
import type { JobPostResolvers } from '../../__codegen__/__graphql__/resolvers'
import {
  V1JobSalaryType,
  V1JobWorkDay,
} from '../../__codegen__/__openapi__/search-server/integratesearch/v4/integratesearch'
import { SALARY_TYPE, WORK_DAYS } from '../constants'

export const JobPost: JobPostResolvers = {
  id(parent) {
    return `JobPost#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  image(parent) {
    if (!parent.images?.length || !parent.images[0].thumbnail) {
      return null
    }
    return {
      thumbnail: parent.images[0].thumbnail,
    }
  },
  regionName(parent) {
    return parent.regionName ?? ''
  },
  targetUri(parent) {
    return parent.targetUri ?? ''
  },
  workDates(parent) {
    if (!parent.workDates) {
      return []
    }
    const workDates = parent.workDates
      .map((workDate: string) => {
        if (!workDate) {
          return null
        }
        return convertStringToDate(workDate)
      })
      .filter(nonNullableTypeSafe)
    return workDates
  },
  workDays(parent) {
    if (!parent.workDays) {
      return []
    }
    const workDays = parent.workDays
      .map((workDay) => {
        switch (workDay) {
          case V1JobWorkDay.MON: {
            return WORK_DAYS.MON.ID
          }
          case V1JobWorkDay.TUE: {
            return WORK_DAYS.TUE.ID
          }
          case V1JobWorkDay.WED: {
            return WORK_DAYS.WED.ID
          }
          case V1JobWorkDay.THU: {
            return WORK_DAYS.THU.ID
          }
          case V1JobWorkDay.FRI: {
            return WORK_DAYS.FRI.ID
          }
          case V1JobWorkDay.SAT: {
            return WORK_DAYS.SAT.ID
          }
          case V1JobWorkDay.SUN: {
            return WORK_DAYS.SUN.ID
          }
          case V1JobWorkDay.UNSPECIFIED:
          default: {
            return null
          }
        }
      })
      .filter(nonNullableTypeSafe)
    return workDays
  },
  authorBadges(parent) {
    if (!parent.authorBadges) {
      return []
    }
    return parent.authorBadges
  },
  salaryType(parent) {
    switch (parent.salaryType) {
      case V1JobSalaryType.MONTHLY: {
        return SALARY_TYPE.MONTHLY.ID
      }
      case V1JobSalaryType.DAILY: {
        return SALARY_TYPE.DAILY.ID
      }
      case V1JobSalaryType.HOURLY: {
        return SALARY_TYPE.HOURLY.ID
      }
      case V1JobSalaryType.PER_CASE: {
        return SALARY_TYPE.PER_CASE.ID
      }
      case V1JobSalaryType.UNSPECIFIED:
      default: {
        return SALARY_TYPE.UNKNOWN.ID
      }
    }
  },
  lastBringUpDate(parent) {
    return convertStringToDate(parent.lastBringUpDate)
  },
  publishedAt(parent) {
    return convertStringToDate(parent.publishedAt)
  },
  cursor(parent) {
    return parent.cursor ?? ''
  },
}
