import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'

export const FleaMarketFilterRecommendQueries: QueryResolvers = {
  async fleaMarketFilterRecommend(parent, args, ctx) {
    const resp = await ctx.services.v4Fleamarket.getFleaMarketFilterRecommend({
      query: args.data.query,
      regionId: args.data.regionId,
      referrer: {
        queryId: args.referrer.queryId,
        queryFromId: args.referrer.queryFromId,
        funnelFromId: args.referrer.funnelFromId,
        tab: args.referrer.tab,
        screenDepthName: args.referrer.screenDepthName,
        searchFunnelId: args.referrer.searchFunnelId,
        experiment: ctx.experiment,
      },
    })

    if (!resp) {
      console.error('FleaMarket FilterRecommend을 불러오는데 실패했습니다.')
      return {}
    }

    return resp
  },
}
