import type { KarrotGroupResolvers } from '../../__codegen__/__graphql__/resolvers'

export const KarrotGroup: KarrotGroupResolvers = {
  id(parent) {
    return `KarrotGroup#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  presentationImage(parent) {
    return parent.presentationImage
      ? {
          thumbnail: parent.presentationImage.thumbnail,
        }
      : null
  },
  someOfMemberProfiles(parent) {
    return (
      parent.someOfMemberProfileImages?.map((imageUrl: string) => {
        return {
          id: `KarrotGroupMember#${imageUrl}`,
          profileImageUrl: imageUrl,
        }
      }) ?? []
    )
  },
}
