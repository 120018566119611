import type { SearchPlaceResolvers } from '../../__codegen__/__graphql__/resolvers'
import { V1OperationStatus } from '../../__codegen__/__openapi__/search-server/place/v4/place'
import { V1DistanceUnit } from '../../__codegen__/__openapi__/search-server/place/v4/place'
import { PLACE_COLLECTION_NAME } from '../constants'

export const SearchPlace: SearchPlaceResolvers = {
  id(parent) {
    return `SearchPlace#${parent.meta.collectionName}-${parent.meta.docId}`
  },
  _id(parent) {
    return parent.source.id
  },
  createdAt(parent) {
    if (!parent.source?.createdAt) {
      return
    }
    return new Date(parent.source.createdAt).toISOString()
  },
  description(parent) {
    return parent.source.description ?? ''
  },
  descriptionWithoutEmoji(parent) {
    return parent.source.descriptionWithoutEmoji ?? ''
  },
  regionName(parent) {
    return parent.fetch.regionName ?? ''
  },
  categoryId(parent) {
    return parent.source.categoryId ?? ''
  },
  categoryText(parent) {
    return parent.source.categoryText ?? ''
  },
  displayCategoryName(parent) {
    return parent.source.displayCategoryName ?? ''
  },
  name(parent) {
    return parent.source.name
  },
  collectionName(parent) {
    switch (parent.meta.collectionName) {
      case 'business-account':
        return PLACE_COLLECTION_NAME.BIZ_ACCOUNT
      case 'poi':
        return PLACE_COLLECTION_NAME.POI
      case 'local-profile':
        return PLACE_COLLECTION_NAME.LOCAL_PROFILE
      default:
        return PLACE_COLLECTION_NAME.UNKNOWN
    }
  },
  location(parent) {
    return parent.source.location ?? ''
  },
  address(parent) {
    return parent.source.address ?? ''
  },
  jibunAddress(parent) {
    return parent.source.jibunAddress ?? ''
  },
  reviewCount(parent) {
    return parent.source.totalReviewCount ?? 0
  },
  followsCount(parent) {
    return parent.source.followsCount ?? 0
  },
  storeOnSale(parent) {
    return parent.source.storeOnSale ?? false
  },
  reservable(parent) {
    return parent.source.reservable ?? false
  },
  hasCoupon(parent) {
    return parent.fetch.hasCoupon ?? false
  },
  distance(parent) {
    if (!parent.source.distance?.isValid) {
      return null
    }

    if (
      !parent.source.distance ||
      !parent.source.distance.unit ||
      !parent.source.distance.value
    ) {
      return null
    }

    return {
      unit: distanceEnumMapper(parent.source.distance.unit),
      value: parent.source.distance.value ?? 0,
    }
  },
  themeIds(parent) {
    return parent.source.themeIds ?? []
  },
  operationStatus(parent) {
    if (!parent.fetch.operationStatus) {
      return null
    }

    return operationStatusEnumMapper(parent.fetch.operationStatus)
  },
  bestReview(parent) {
    if (!parent.source.bestReview) {
      return null
    }

    return {
      userName: parent.source.bestReview?.userName,
      content: parent.source.bestReview?.content,
    }
  },
  picture(parent) {
    return parent.fetch.picture
      ? {
          url: parent.fetch.picture.url,
          thumbnail: parent.fetch.picture.thumbnail,
          medium: parent.fetch.picture.medium,
        }
      : null
  },
  bgImage(parent) {
    return parent.fetch.bgImage
      ? {
          url: parent.fetch.bgImage.url,
          thumbnail: parent.fetch.bgImage.thumbnail,
          medium: parent.fetch.bgImage.medium,
        }
      : null
  },
  reviewStarRatingAverage(parent) {
    return parent.source.reviewStarRatingAverage ?? 0
  },
  images(parent) {
    return (
      parent.fetch.images?.map((image) => ({
        url: image.url,
        thumbnail: image.thumbnail,
        medium: image.medium,
      })) ?? []
    )
  },
}

const distanceEnumMapper = (distanceUnit: V1DistanceUnit) => {
  switch (distanceUnit) {
    case V1DistanceUnit.METER:
      return 'METER'
    case V1DistanceUnit.KILOMETER:
      return 'KILOMETER'
    case V1DistanceUnit.UNSPECIFIED:
    default:
      return 'UNKNOWN'
  }
}

const operationStatusEnumMapper = (operationStatus: V1OperationStatus) => {
  switch (operationStatus) {
    case V1OperationStatus.UNSPECIFIED:
      return 'OPERATION_STATUS_UNSPECIFIED'
    case V1OperationStatus.OPEN:
      return 'OPERATION_STATUS_OPEN'
    case V1OperationStatus.CLOSED_FOR_PREPARATION:
      return 'OPERATION_STATUS_CLOSED_FOR_PREPARATION'
    case V1OperationStatus.BREAK_TIME:
      return 'OPERATION_STATUS_BREAK_TIME'
    case V1OperationStatus.CLOSED_TODAY:
      return 'OPERATION_STATUS_CLOSED_TODAY'
    case V1OperationStatus.OPEN_24_HOURS:
      return 'OPERATION_STATUS_OPEN_24_HOURS'
    default:
      return 'UNKNOWN'
  }
}
