import type { DspAdvertisementNodeResolvers } from '../../__codegen__/__graphql__/resolvers'
import { DSP_ADVERTISEMENT_COMPONENT_TYPE } from '../constants'

export const DSPAdvertisementNode: DspAdvertisementNodeResolvers = {
  componentType(parent) {
    switch (parent.componentType) {
      case 'DSPAD_COMPONENT_TYPE_CATALOG_ADVERTISEMENT': {
        return DSP_ADVERTISEMENT_COMPONENT_TYPE.CATALOG_ADVERTISEMENT
      }
      case 'DSPAD_COMPONENT_TYPE_CAROUSEL_ADVERTISEMENT': {
        return DSP_ADVERTISEMENT_COMPONENT_TYPE.CAROUSEL_ADVERTISEMENT
      }
      default: {
        return DSP_ADVERTISEMENT_COMPONENT_TYPE.UNKNOWN
      }
    }
  },
}
